import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { s3url } from "../../helper/endpoint";
import { confetti } from "tsparticles-confetti";
import axios from "axios";

import coins from "../../assets/new/tfl-coins-website.png";
import tflLogo from "../../assets/tfllogo.png";
import {
  Row,
  Col,
  Avatar,
  Card,
  Tag,
  Table,
  Descriptions,
  Tabs,
  Skeleton,
  Divider,
  Popconfirm,
  Collapse,
  Popover,
  Alert,
  Steps
} from "antd";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

import BottomFooter from "../Footer/BottomFooter";

import bellBig from "../../assets/new/big-bell.png";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { userAction } from "../../store/slices/userslices";
import { FaCopy, FaSignOutAlt } from "react-icons/fa";

import { isMobile } from "react-device-detect";
import MobileNavBottom from "../Footer/MobileNavBarBottom";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import coinsText from "../../assets/new/tfl coin text.png";
import ScrollToTop from "../../components/ScrollToTop";

export default function Profile({ copyText }) {
  const { Step } = Steps;
  const [searchParams] = useSearchParams();
  let userID = localStorage.getItem("userId");
  let navigate = useNavigate("");
  const { Panel } = Collapse;
  let dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coinCode, setCoinCode] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [tflResponseData, setTFLResponseData] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [userPoints, setUserPoints] = useState("0");
  const [pointsRedeemed, setPointsRedeemed] = useState("0");
  const [userPointData, setUserPointData] = useState([]);
  const [modalVar, setModalVar] = useState({});
  const [defaultOpenTab, setDefaultOpenTab] = useState("points");
  let [alertMsg, setAlertMsg] = useState("");

  let user = useSelector((state) => state.userReducer);

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    setShow(true);
    setModalVar(e);
  };

  const columns = [
    {
      title: "Antall",
      dataIndex: "quantity",
      key: "quantity",
      width: "1%"
    },
    {
      title: "Gjenstandsnavn",
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: "Pris",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "5%",
      render: (record, item) => {
        return "Kr. " + Number(item.price * item.quantity).toFixed(2);
      }
    }
  ];

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    let openTab = searchParams.get("openTab");

    if (openTab) {
      if (openTab == "referral") {
        setDefaultOpenTab("referralCode");
      }

      if (openTab == "coupon") {
        setDefaultOpenTab("coupon");
      }

      if (openTab == "points") {
        setDefaultOpenTab("points");
      }
      if (openTab == "referralCode") {
        setDefaultOpenTab("referralCode");
      }
      if (openTab == "tflOrderHistory") {
        setDefaultOpenTab("tflOrderHistory");
      }
    }
  }, []);

  useEffect(() => {
    console.log("---open tab---", defaultOpenTab);
    if (defaultOpenTab == "points") {
      getPoints();
    }
    if (defaultOpenTab == "coupon") {
      getCoupons();
    }
  }, [defaultOpenTab]);

  const getPoints = async () => {
    let cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };
    setLoading(true);
    await axios
      .post(`${apiUrl}${tDriveEndpoint}appuser/getuserpoints`, cardHistoryData)
      .then((response) => {
        console.log("response", response.data.data.points);
        localStorage.setItem("userPoints", response.data.data.points);
        setUserPoints(response.data.data.points);
        setPointsRedeemed(response.data.data.pointsRedeemed);
        setUserPointData(response.data.data.pointsArr);
        setLoading(false);
      })
      .catch((error) => {
        console.log("---error user---", error);
      });
  };

  const getCoupons = async () => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };
    setLoading(true);

    await axios
      .post(`${apiUrl}${tDriveEndpoint}appuser/getusercoupons`, cardHistoryData)
      .then((response) => {
        setCouponData(response.data.data.coupons);
        setLoading(false);
      })
      .catch((error) => {
        console.log("---error user---", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let firstName = e.target.inputFirstName.value;
    let lastName = e.target.inputLastName.value;
    let token = user.token;

    let inputFields = [firstName, lastName, token];

    if (!validateForm(inputFields)) {
      console.log(firstName, lastName, token);
    } else {
      setAlertMsg("Vennligst vent...");

      let formData = {
        firstName: firstName,
        lastName: lastName
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/updateuser`, formData, {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        })
        .then((response) => {
          console.log("---response server---", response);

          if (response.data.status == 200) {
            setAlertMsg("Profile updated successfully.");

            let updateData = {
              firstName: response.data.data.firstName,
              lastName: response.data.data.lastName,
              email: user.email,
              phone: user.phone,
              countryCode: user.countryCode,
              id: user._id,
              refferalCode: user.userReferralCode,
              token: user.token
            };

            dispatch(userAction.saveUser(updateData));
          } else {
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
        });
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    let oldPassword = e.target.inputOldPassword.value;
    let newPassword = e.target.inputNewPassword.value;
    let conPassword = e.target.inputConPassword.value;

    let inputFields = [oldPassword, newPassword, conPassword];

    if (!validateForm(inputFields)) {
      console.log(oldPassword, newPassword, conPassword);
      setAlertMsg("All fields are required.");
    } else {
      if (newPassword != conPassword) {
        setAlertMsg("Please confirm your new password.");
        return;
      }

      setAlertMsg("Vennligst vent...");

      let formData = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        userId: user._id
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/updatepassword`, formData)
        .then((response) => {
          if (response.data.status == 200) {
            setAlertMsg("Password updated successfully, please login again");
            refreshAfterLogout();
            window.location.reload();
          } else {
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
        });
    }
  };

  const { TabPane } = Tabs;
  let [historyCount, setHistoryCount] = useState(0);
  let [tflHistoryCount, setTflHistoryCount] = useState(0);

  const clickFunc = async (key) => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };

    setAlertMsg("");
    if (key === "orderHistory") {
      setLoading(true);
      setDefaultOpenTab("orderHistory");

      await axios
        .post(
          `${apiUrl}${tDriveEndpoint}appuser/appuserallorders`,
          cardHistoryData
        )
        .then((response) => {
          setResponseData(response.data.data.data);
          setHistoryCount(response.data.data.totalOrders);
          setLoading(false);
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
        });
    } else if (key === "coupon") {
      setDefaultOpenTab("coupon");
    } else if (key === "tflOrderHistory") {
      setLoading(true);

      await axios
        .post(
          `${apiUrl}${tDriveEndpoint}appuser/tfluserallorders`,
          cardHistoryData
        )
        .then((response) => {
          console.log("response.data.data.data", response.data.data.data);
          setTFLResponseData(response.data.data.data);
          console.log("tflResponseData", tflResponseData);
          setTflHistoryCount(response.data.data.totalOrders);
          setLoading(false);
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
        });
    }

    if (key === "points") {
      setDefaultOpenTab("points");
    }

    if (key === "editProfile") {
      setDefaultOpenTab("editProfile");
    }

    if (key === "setting") {
      setDefaultOpenTab("setting");
    }
    if (key === "referralCode") {
      setDefaultOpenTab("referralCode");
    }
    if (key === "tflOrderHistory") {
      setDefaultOpenTab("tflOrderHistory");
    }
  };

  const columnsData = [
    {
      title: "Gavekort i NOK",
      dataIndex: "nok",
      key: "giftCardInNOK"
    },
    {
      title: "Antall mynter",
      dataIndex: "points",
      key: "coins"
    },
    {
      title: "Handling",
      key: "action",
      render: (_, record) =>
        userPointData.length >= 1 ? (
          <Popconfirm
            title={
              "Do you really want to redeem " +
              record.points +
              " coins for " +
              record.nok +
              " NOK coupon?"
            }
            onConfirm={() => handleRedeem(record._id)}
          >
            <Button type="primary">Redeem</Button>
          </Popconfirm>
        ) : null
    }
  ];
  const handleRedeem = (id) => {
    {
      console.log("record key", id);
    }
    let payload = {
      appUserId: userID,
      redeemId: id
    };
    async function fetchData() {
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/treeDrive/appuser/redeempoints`,
          payload
        );

        if (response.data.status == 500) {
          alert(response.data.message);
        }

        setCoinCode(response.data.data.coupon.coupanCode);
        console.log("response is getting", response.data.data.coupon);
        setOpenModal(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  };

  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());
  };

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const content = (
    <ol>
      <li>
        <b>Bekreft gavekortdetaljer</b>
        <ul>
          <li>
            Sjekk utløpsdatoen på gavekortet ditt for å sikre at det fortsatt er
            gyldig.
          </li>
          <li>
            Sørg for at gavekortet ditt har en tilstrekkelig saldo til å dekke
            det tiltenkte kjøpet.
          </li>
        </ul>
      </li>
      <li>
        <b>Løs inn gavekortet ditt</b>
        <ul>
          <li>På betalingssiden finner du delen for å angi gavekortkoden.</li>
          <li>Skriv inn gavekortkoden nøye i det angitte feltet.</li>
        </ul>
      </li>
      <li>
        <b>Fullfør kjøpet</b>
        <ul>
          <li>Bruk koden og fortsett med betalingsprosessen som anvist.</li>
          <li>Bekreft at gavekortsaldoen er brukt på bestillingen din.</li>
        </ul>
      </li>
      <li>
        <b>Nyt måltidet</b>
        <ul>
          <li>
            Når betalingen er behandlet, kan du nyte måltidet med verdien fra
            gavekortet ditt.
          </li>
        </ul>
      </li>
      <p>
        <i>Takk for at du valgte å spise hos oss!</i>
      </p>
    </ol>
  );

  const tflColumns = [
    {
      title: "Gjenstandsnavn",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      title: "Pris",
      dataIndex: "totalAmount",
      key: "totalAmount"
    }
  ];
  // Calculate total quantity and total price
  const totalQuantity =
    modalVar?.items?.reduce((total, item) => total + item.quantity, 0) || 0;
  const totalPriceOfItems =
    modalVar?.items?.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    ) || 0;

  console.log("Total Quantity of Items:", totalQuantity);
  console.log("Total Price of Items:", totalPriceOfItems);
  console.log("modalVar", modalVar.items);
  // Assuming `currentOrderStatus` holds the current status of the order, e.g., "PENDING", "CONFIRMED", "SHIPPED", or "DELIVERED"
  const currentOrderStatus = "PENDING"; // Replace with the actual status value
  const stepsData = {
    DELIVERED: {
      current: 4,
      steps: ["PENDING", "IN-PROCESS", "SHIPPED", "DELIVERED"]
    },
    CANCEL: { current: 1, steps: ["CANCEL"], status: "error" },
    SHIPPED: {
      current: 3,
      steps: ["PENDING", "IN-PROCESS", "SHIPPED", "DELIVERED"],
      style: { color: "red" }
    },
    PENDING: {
      current: 1,
      steps: ["PENDING", "IN-PROCESS", "SHIPPED", "DELIVERED"],
      style: { color: "red" }
    },
    "IN-PROCESS": {
      current: 2,
      steps: ["PENDING", "IN-PROCESS", "SHIPPED", "DELIVERED"],
      style: { color: "red" }
    }
  };

  const getOrderSteps = (orderStatus) => {
    const { current, steps, status, style } = stepsData[orderStatus] || {};
    return steps ? (
      <Steps
        labelPlacement="horizontal"
        direction="horizontal"
        className="custom-steps w-100"
        progressDot
        size="small"
        current={current}
        status={status}
        style={style}
      >
        {steps.map((title) => (
          <Step key={title} title={title} />
        ))}
      </Steps>
    ) : null;
  };

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages p-5 mb-3">
          <Row className="container py-5 mt-4 mobileProfile profileRow  align-items-center justify-content-center">
            <Col span={20}>
              <Row className="justify-content-between mb-3 p-3">
                {" "}
                <Col>
                  {" "}
                  <h3>
                    Hei,&nbsp;
                    <i>
                      {user.firstName} {user.lastName}!
                    </i>
                  </h3>
                  Henvisningskode :&nbsp;
                  <a
                    title="Copy Link"
                    className="copytextCursor text-dark"
                    onClick={() =>
                      navigator.clipboard.writeText(`${user.refferalCode}`)
                    }
                  >
                    {" "}
                    <u>
                      {user.refferalCode} <FaCopy />
                    </u>
                  </a>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <NavLink
                    to="/home"
                    className=" aboutNavLink logoutBtn"
                    onClick={refreshAfterLogout}
                  >
                    <FaSignOutAlt />
                  </NavLink>
                </Col>
              </Row>
              <div className="profileBox">
                <Tabs
                  onTabClick={clickFunc}
                  type="card"
                  activeKey={defaultOpenTab}
                >
                  <TabPane
                    tab="Rediger Profil"
                    key="editProfile"
                    className="px-3 pb-4"
                  >
                    <h2 className="text-dark">Profil Informasjon</h2>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group
                        className="row  mb-3 mt-2"
                        controlId="inputFirstName"
                      >
                        <Col sm={12}>
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label="Fornavn*"
                          >
                            <Form.Control
                              required
                              type="text"
                              placeholder="Fornavn"
                              name="inputFirstName"
                              defaultValue={user.firstName || ""}
                              onChange={(e) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  firstName: e.target.value
                                }));
                              }}
                            />
                          </FloatingLabel>
                        </Col>

                        <Col sm={12}>
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label="Etternavn*"
                          >
                            <Form.Control
                              required
                              type="text"
                              placeholder="Etternavn"
                              name="inputLastName"
                              defaultValue={user.lastName}
                              onChange={(e) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  lastName: e.target.value
                                }));
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        className="row mb-3"
                        controlId="formBasicEmail"
                      >
                        <Col sm={12}>
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label="Telefonnummer*"
                          >
                            <Form.Control
                              disabled={true}
                              required
                              type="number"
                              placeholder="Telefonnummer"
                              name="inputPhone"
                              defaultValue={user.phone}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col sm={12}>
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label="E-post*"
                          >
                            <Form.Control
                              disabled={true}
                              required
                              type="email"
                              placeholder="name@example.com"
                              name="inputEmail"
                              defaultValue={user.email}
                            />
                          </FloatingLabel>
                        </Col>
                      </Form.Group>

                      <Row>
                        <span style={{ color: "#333" }}>{alertMsg}</span>
                      </Row>

                      <Button variant="primary" type="submit">
                        Oppdater profil
                      </Button>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab="Innstilling"
                    key="setting"
                    className="px-3 pb-4"
                  >
                    <div>
                      <h2 className="text-dark">Bytt passord</h2>
                      <Form noValidate onSubmit={updatePassword}>
                        <Form.Group
                          className="row  mb-3 mt-2"
                          controlId="formBasicPassword"
                        >
                          <Col>
                            <FloatingLabel
                              controlId="floatingInputGrid"
                              label="Gammelt Passord*"
                            >
                              <Form.Control
                                required
                                type="password"
                                name="inputOldPassword"
                              />
                            </FloatingLabel>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          className="row mb-3"
                          controlId="formBasicPassword"
                        >
                          <Col sm={12}>
                            <FloatingLabel
                              controlId="floatingInputGrid"
                              label="Nytt passord*"
                            >
                              <Form.Control
                                required
                                type="password"
                                name="inputNewPassword"
                              />
                            </FloatingLabel>
                          </Col>

                          <Col sm={12}>
                            <FloatingLabel
                              controlId="floatingInputGrid"
                              label="Bekrefte nytt passord*"
                            >
                              <Form.Control
                                required
                                type="password"
                                placeholder=""
                                name="inputConPassword"
                              />
                            </FloatingLabel>
                          </Col>
                        </Form.Group>

                        <Row>
                          <span style={{ color: "#333" }}>{alertMsg}</span>
                        </Row>

                        <Button variant="primary" type="submit">
                          Oppdater passord
                        </Button>
                      </Form>
                    </div>
                  </TabPane>
                  <TabPane
                    tab="Ordrehistorikk"
                    key="orderHistory"
                    className="px-3 pb-4"
                  >
                    {loading ? (
                      <>
                        <Row>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {!historyCount ? (
                          <p className="text-dark">Du har ikke bestilt ennå!</p>
                        ) : (
                          <Row className="justify-content-between">
                            {responseData.map((data, index) => {
                              return (
                                <Col
                                  md={{ span: 12 }}
                                  xl={{ span: 8 }}
                                  className="px-3"
                                >
                                  <Card
                                    className="historyCard mt-4"
                                    key={data._id}
                                    actions={[
                                      <>
                                        <Button
                                          variant="primary"
                                          onClick={() => handleShow(data)}
                                          style={{
                                            width: "100%",
                                            borderRadius: "0px 0px 5px 5px"
                                          }}
                                        >
                                          Se detaljer
                                        </Button>
                                      </>
                                    ]}
                                  >
                                    <Row
                                      className="justify-content-between"
                                      id="cardHistory"
                                    >
                                      <Col
                                        sm={8}
                                        className="d-flex justify-content-center align-items-center"
                                      >
                                        <Avatar
                                          src={`${s3url}/${data?.branchId?.logo}`}
                                        />
                                      </Col>
                                      <Col sm={1} />
                                      <Col
                                        sm={15}
                                        className="d-flex flex-column justify-content-center align-items-start orderData"
                                      >
                                        <h4>
                                          {data?.branchId?.name},&nbsp;
                                          {data?.branchId?.address?.city}
                                        </h4>
                                        <Divider />
                                        <p className="pt-2">
                                          <b>OTP:</b> <span>{data?.otp}</span>
                                        </p>
                                        <Tag
                                          color="#f50"
                                          className="tagOrderStatus"
                                        >
                                          {data?.orderStatus}
                                        </Tag>
                                        <Divider />
                                        <span>
                                          {data?.createdAt ? (
                                            new Date(
                                              data.createdAt
                                            ).toLocaleString()
                                          ) : (
                                            <>{data?.createdAt}</>
                                          )}
                                        </span>
                                        <p>
                                          Ordretype:{" "}
                                          <span>{data?.orderType}</span>
                                        </p>
                                        {data?.orderStatus == "IN-PROCESS" &&
                                        data?.orderType == "Drive Out" ? (
                                          <>
                                            <p>
                                              Førerens navn:
                                              <span>
                                                {data?.driverId?.firstName}
                                                {data?.driverId?.lastName}
                                              </span>
                                            </p>
                                            <p>
                                              Sjåførens kontaktnummer:
                                              <span>
                                                {data?.driverId?.phoneNumber}
                                              </span>
                                            </p>
                                          </>
                                        ) : (
                                          ""
                                        )}{" "}
                                        <Divider />
                                        <div>
                                          <p>
                                            Totalt betalt:&nbsp;
                                            <span>Kr. {data?.totalPrice}</span>
                                          </p>
                                          <p>
                                            Quantity:&nbsp;
                                            <span>
                                              {data?.items[0]?.quantity}
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              );
                            })}
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modalHistory"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className="text-dark">
                                  {modalVar?.branchId?.name} &nbsp;
                                  <Tag color="#f50">
                                    {modalVar?.orderStatus}
                                  </Tag>
                                  <br />
                                  <i>OTP : {modalVar?.otp}</i>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="text-dark">
                                <Table
                                  columns={columns}
                                  dataSource={modalVar.items}
                                />
                                <Descriptions
                                  bordered
                                  column={{
                                    xxl: 1,
                                    xl: 1,
                                    lg: 1,
                                    md: 1,
                                    sm: 1,
                                    xs: 1
                                  }}
                                >
                                  <Descriptions.Item label="Total Product Price">
                                    Kr. {/* {modalVar?.totalPrice} */}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Total Extra Price">
                                    Kr. {modalVar?.items?.totalExtraPrice}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Service Tax">
                                    Kr. {modalVar?.tdServiceCharge}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Discount">
                                    Kr. {modalVar?.discount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Driveout Charges">
                                    Kr. {modalVar?.tdDriveOutCharge}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Total Amount">
                                    Kr. {modalVar?.taxPrice}
                                  </Descriptions.Item>
                                </Descriptions>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="primary" onClick={handleClose}>
                                  Greit
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </Row>
                        )}
                      </>
                    )}
                  </TabPane>
                  <TabPane
                    tab="TFL Gift Card"
                    key="coupon"
                    className="px-3 pb-4"
                  >
                    {/* web dimension */}
                    {loading ? (
                      <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                          <Skeleton avatar paragraph={{ rows: 4 }} />
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                          <Skeleton avatar paragraph={{ rows: 4 }} />
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                          <Skeleton avatar paragraph={{ rows: 4 }} />
                        </Col>
                      </Row>
                    ) : (
                      <Row className="">
                        <Col span={24}>
                          <h2 className="text-dark px-3">TFL Gift Cards</h2>
                        </Col>
                        <Alert
                          className="my-3"
                          closable
                          message="Utløpte TFL-kort eller gavekort uten saldo fjernes automatisk. For andre henvendelser, kontakt oss på kontakt@treedrive.no."
                          type="info"
                          showIcon
                        />
                        <Col span={24}>
                          <Popover
                            placement="bottom"
                            title="Info"
                            content={content}
                            trigger="click"
                            className="px-3"
                          >
                            <p>
                              <u>
                                <i>
                                  *Klikk for å vite - Hvordan bruker jeg
                                  TFL-gavekort?
                                </i>
                              </u>
                            </p>
                          </Popover>
                        </Col>

                        {couponData.map((data, index) => {
                          return (
                            <Col
                              md={{ span: 12 }}
                              xl={{ span: 8 }}
                              className="p-3"
                            >
                              <Card className="giftCardBox" key={data._id}>
                                <img src={tflLogo} width={"200px"} />
                                <h5
                                  title="Copy Link"
                                  className="copytextCursor text-dark pt-3"
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      `${data?.coupanCode}`
                                    )
                                  }
                                >
                                  {" "}
                                  <u>
                                    {data?.coupanCode} <FaCopy />
                                  </u>
                                </h5>
                                <div>
                                  <p>
                                    Kupongverdi:{" "}
                                    <span>Kr. {data?.coupanValue}</span>
                                  </p>
                                  <p>
                                    Gjenværende verdi:{" "}
                                    <span>Kr. {data?.reamainigValue}</span>
                                  </p>
                                  <p>
                                    Utløpsdato :{" "}
                                    {/* <span>{data?.expirayDate}</span> */}
                                    <span>
                                      {data?.expirayDate ? (
                                        new Date(
                                          data.expirayDate
                                        ).toLocaleString()
                                      ) : (
                                        <>{data?.expirayDate}</>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tab="TFL Coins" key="points" className="px-3 pb-4">
                    {loading ? (
                      <Row>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Row>
                    ) : (
                      <Row className="justify-content-between">
                        {" "}
                        <Col span={14} className="px-3">
                          <img src={coinsText} alt="coins text" />
                          <p>
                            Vi introduserer vårt banebrytende
                            <b>
                              <i> TFL-myntsystem, </i>
                            </b>
                            som du kan tjene ved hver bestilling eller ved å
                            henvise
                            <b>
                              {" "}
                              <i> TD Ordering-appen</i>
                            </b>{" "}
                            til dine kjære. Disse myntene kan konverteres til
                            reelle verdier.
                            <br />
                            <br />
                            Akkumuler
                            <b>
                              {" "}
                              <i> TFL-mynter</i>{" "}
                            </b>
                            enkelt med hver bestilling, og løs dem inn på Tree
                            Drive-plattformen for eksklusive belønninger.
                          </p>
                          <br />
                          <p>
                            {" "}
                            Bli med oss i dag for å låse opp en verden av
                            fordeler og besparelser, alt til fingerspissene. For
                            mer info besøk &nbsp;{" "}
                            <a href="https://treedrive.no">
                              <b>
                                <u>
                                  <i>www.treedrive.no</i>{" "}
                                </u>
                              </b>
                            </a>
                          </p>
                        </Col>
                        <Col
                          span={8}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <img
                            src={coins}
                            alt="coins"
                            width="300px"
                            className="coinPotali"
                          />
                        </Col>
                        <Divider />
                        <Col span={24} className="px-3">
                          <br />
                          <h5>Tilgjengelige mynter: {userPoints}</h5>
                          <h6>Brukte mynter: {pointsRedeemed}</h6>
                          <h6>
                            Totalt opptjente mynter:{" "}
                            <b>{userPoints + pointsRedeemed}</b>
                          </h6>
                          <p>
                            <i>
                              <b>
                                *Dine mynter vil bli oppdatert innen én time
                                etter ordrebekreftelsen.
                              </b>
                            </i>
                          </p>
                          <br />
                        </Col>
                        <Divider />
                        <Col
                          span={24}
                          className="d-flex justify-content-center"
                        >
                          <h5>
                            <br />
                            <b>Innløsningsordning</b>
                          </h5>
                          <br />
                        </Col>
                        <Col span={24}>
                          {console.log(
                            "userPointsData.pointsArr in Col",
                            userPointData
                          )}
                          <Table
                            className="pointsTable"
                            columns={columnsData}
                            dataSource={userPointData}
                            bordered
                          />
                        </Col>
                        <Col span={24}>
                          <p>
                            <i>
                              <u>
                                *Sjekk gavekortene dine i gavekortfanen ovenfor!
                              </u>
                            </i>
                          </p>
                        </Col>
                        <Row className="p-3 bottomThought">
                          <Col span={24}>
                            <img src={bellBig} />
                          </Col>
                          <Col span={24} className="mt-3">
                            <p>The more you collect. The more you earn!</p>
                          </Col>
                        </Row>
                      </Row>
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </section>
      )}
      {isMobile && (
        <section className="mission pb-3">
          <div
            className="container profileRow py-2 position-fixed bg-light mobileProfile"
            style={{ zIndex: 999, top: 0 }}
          >
            <Row className="justify-content-between ">
              {" "}
              <Col>
                {" "}
                <h3>
                  Hei,&nbsp;
                  <i>
                    {user.firstName} {user.lastName}!
                  </i>
                </h3>
                Henvisningskode :&nbsp;
                <a
                  title="Copy Link"
                  className="copytextCursor text-dark"
                  onClick={() =>
                    navigator.clipboard.writeText(`${user.refferalCode}`)
                  }
                >
                  {" "}
                  <u>
                    {user.refferalCode} <FaCopy />
                  </u>
                </a>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <NavLink
                  to="/home"
                  className=" aboutNavLink logoutBtn"
                  onClick={refreshAfterLogout}
                >
                  <FaSignOutAlt />
                </NavLink>
              </Col>
            </Row>
          </div>
          <Row className="profileBox px-0 py-4 mt-5 mx-0" id="tflPoints">
            <Tabs onTabClick={clickFunc} type="card" activeKey={defaultOpenTab}>
              {" "}
              <TabPane
                tab="Rediger Profil"
                key="editProfile"
                className="px-3 py-4"
              >
                <h2 className="text-dark">Profil Informasjon</h2>
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group
                    className="row mb-3 mt-2"
                    controlId="inputFirstName"
                  >
                    <Col sm={24}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Fornavn*"
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder="Fornavn"
                          name="inputFirstName"
                          defaultValue={user.firstName || ""}
                          onChange={(e) => {
                            console.log(
                              "inside the first Name---->",
                              e.target.value
                            );
                            setFormData((prevState) => ({
                              ...prevState,
                              firstName: e.target.value
                            }));
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Form.Group>{" "}
                  <Form.Group className="row mb-3" controlId="inputLastName">
                    <Col sm={24}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Etternavn*"
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder="Etternavn"
                          name="inputLastName"
                          defaultValue={user.lastName}
                          onChange={(e) => {
                            console.log(
                              "inside the first Name---->",
                              e.target.value
                            );
                            setFormData((prevState) => ({
                              ...prevState,
                              lastName: e.target.value
                            }));
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Form.Group>
                  <Form.Group className="row mb-3" controlId="formBasicNumber">
                    <Col sm={24}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Telefonnummer*"
                      >
                        <Form.Control
                          disabled={true}
                          required
                          type="number"
                          placeholder="Telefonnummer"
                          name="inputPhone"
                          defaultValue={user.phone}
                        />
                      </FloatingLabel>
                    </Col>
                  </Form.Group>
                  <Form.Group className="row mb-3" controlId="formBasicEmail">
                    <Col sm={24}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="E-post*"
                      >
                        <Form.Control
                          disabled={true}
                          required
                          type="email"
                          placeholder="name@example.com"
                          name="inputEmail"
                          defaultValue={user.email}
                        />
                      </FloatingLabel>
                    </Col>
                  </Form.Group>
                  <Row>
                    <span style={{ color: "#333" }}>{alertMsg}</span>
                  </Row>
                  <Button variant="primary" type="submit">
                    Oppdater profil
                  </Button>
                </Form>
              </TabPane>
              <TabPane tab="Innstilling" key="setting" className="px-3 py-4">
                <div>
                  <h2 className="text-dark">Bytt passord</h2>
                  <Form noValidate onSubmit={updatePassword}>
                    <Form.Group
                      className="row mb-3 mt-2"
                      controlId="formBasicPassword"
                    >
                      <Col>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Gammelt Passord*"
                        >
                          <Form.Control
                            required
                            type="password"
                            name="inputOldPassword"
                          />
                        </FloatingLabel>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      className="row mb-3"
                      controlId="formBasicPassword"
                    >
                      <Col sm={12}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Nytt passord*"
                        >
                          <Form.Control
                            required
                            type="password"
                            name="inputNewPassword"
                          />
                        </FloatingLabel>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      className="row mb-3"
                      controlId="formBasicPassword"
                    >
                      <Col sm={12}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Bekrefte nytt passord*"
                        >
                          <Form.Control
                            required
                            type="password"
                            placeholder=""
                            name="inputConPassword"
                          />
                        </FloatingLabel>
                      </Col>
                    </Form.Group>

                    <Row>
                      <span style={{ color: "#333" }}>{alertMsg}</span>
                    </Row>

                    <Button variant="primary" type="submit">
                      Oppdater passord
                    </Button>
                  </Form>
                </div>
              </TabPane>
              <TabPane
                tab="Rekkefølge Historie"
                key="orderHistory"
                className="px-0 pb-5 mb-5"
              >
                {/* for mobile */}
                {loading ? (
                  <>
                    <Row>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {!historyCount ? (
                      <p className="text-dark">Du har ikke bestilt ennå!</p>
                    ) : (
                      <Row className="justify-content-between">
                        <h2 className="text-dark px-3">Rekkefølge Historie</h2>
                        {responseData.map((data, index) => {
                          return (
                            <Col xs={{ span: 24 }} className="px-3">
                              <Card
                                className="historyCard mt-4"
                                key={data._id}
                                actions={[
                                  <>
                                    <Button
                                      variant="primary"
                                      onClick={() => handleShow(data)}
                                      style={{
                                        width: "100%",
                                        borderRadius: "0px 0px 5px 5px"
                                      }}
                                    >
                                      Se detaljer
                                    </Button>
                                  </>
                                ]}
                              >
                                <Row
                                  className="justify-content-between"
                                  id="cardHistory"
                                >
                                  <Col
                                    span={8}
                                    className="d-flex justify-content-center align-items-center"
                                  >
                                    <Avatar
                                      src={`${s3url}/${data?.branchId?.logo}`}
                                    />
                                  </Col>
                                  <Col
                                    span={15}
                                    className="d-flex flex-column justify-content-center align-items-start orderData"
                                  >
                                    <h4>
                                      {data?.branchId?.name},
                                      {data?.branchId?.address?.city}
                                    </h4>
                                    <Divider />
                                    <p className="pt-2">
                                      <b>OTP:</b> <span>{data?.otp}</span>
                                    </p>
                                    <Tag
                                      color="#f50"
                                      className="tagOrderStatus"
                                    >
                                      {data?.orderStatus}
                                    </Tag>
                                  </Col>
                                </Row>
                                <Divider />
                                <Row>
                                  <p>
                                    Ordretype: <span>{data?.orderType}</span>
                                  </p>
                                  <p>
                                    Ordre Date & Time:{" "}
                                    <span>
                                      {data?.createdAt ? (
                                        new Date(
                                          data.createdAt
                                        ).toLocaleString()
                                      ) : (
                                        <>{data?.createdAt}</>
                                      )}
                                    </span>
                                  </p>
                                  {data?.orderStatus == "IN-PROCESS" &&
                                  data?.orderType == "Drive Out" ? (
                                    <>
                                      <p>
                                        Førerens navn:
                                        <span>
                                          {data?.driverId?.firstName}
                                          {data?.driverId?.lastName}
                                        </span>
                                      </p>
                                      <p>
                                        Sjåførens kontaktnummer:
                                        <span>
                                          {data?.driverId?.phoneNumber}
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  <Divider />
                                  <div>
                                    <p>
                                      Totalt betalt:&nbsp;
                                      <span>Kr. {data?.totalPrice}</span>
                                    </p>
                                    <p>
                                      Quantity:&nbsp;
                                      <span>{data?.items[0]?.quantity}</span>
                                    </p>
                                  </div>
                                </Row>
                              </Card>
                            </Col>
                          );
                        })}
                        <Modal
                          style={{ height: "80vh", overflowY: "scroll" }}
                          show={show}
                          onHide={handleClose}
                          className="modalHistory"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title className="text-dark">
                              {modalVar?.branchId?.name} &nbsp;
                              <Tag color="#f50">{modalVar?.orderStatus}</Tag>
                              <br />
                              <i>OTP : {modalVar?.otp}</i>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="text-dark">
                            <Table
                              columns={columns}
                              dataSource={modalVar.items}
                            />
                            <Descriptions
                              bordered
                              column={{
                                xxl: 1,
                                xl: 1,
                                lg: 1,
                                md: 1,
                                sm: 1,
                                xs: 1
                              }}
                            >
                              <Descriptions.Item label="Sub Total">
                                Kr.
                                {(
                                  totalPriceOfItems -
                                  (totalPriceOfItems -
                                    (100 * totalPriceOfItems) / (100 + 15))
                                ).toFixed(2)}
                              </Descriptions.Item>
                              <Descriptions.Item label="MVA">
                                Kr.{" "}
                                {(
                                  totalPriceOfItems -
                                  (100 * totalPriceOfItems) / (100 + 15)
                                ).toFixed(2)}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label={`Utkjøring Pris (${modalVar?.distance} Km)`}
                              >
                                Kr.{" "}
                                {(modalVar?.tdDriveOutCharge / 1.25).toFixed(2)}
                              </Descriptions.Item>
                              <Descriptions.Item label="Utkjøring mva">
                                Kr.{" "}
                                {(
                                  (modalVar?.tdDriveOutCharge / 1.25) *
                                  0.25
                                ).toFixed(2)}
                              </Descriptions.Item>
                              <Descriptions.Item label="App-avgift">
                                Kr. {modalVar?.tdServiceCharge / 1.25}
                              </Descriptions.Item>
                              <Descriptions.Item label="App-av. mva">
                                Kr. {(modalVar?.tdServiceCharge / 1.25) * 0.25}
                              </Descriptions.Item>
                              <Descriptions.Item label="Total Amount">
                                Kr. {modalVar?.totalPrice}
                              </Descriptions.Item>
                            </Descriptions>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                              Greit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Row>
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tab="TFL Gift Card" key="coupon" className="px-0 pb-4">
                {loading ? (
                  <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Skeleton avatar paragraph={{ rows: 4 }} />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Skeleton avatar paragraph={{ rows: 4 }} />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Skeleton avatar paragraph={{ rows: 4 }} />
                    </Col>
                  </Row>
                ) : (
                  <Row className="justify-content-between">
                    <h2 className="text-dark px-3">TFL Gift Cards</h2>

                    <Alert
                      className="my-3"
                      closable
                      message="Utløpte TFL-kort eller gavekort uten saldo fjernes automatisk. For andre henvendelser, kontakt oss på kontakt@treedrive.no."
                      type="info"
                      showIcon
                    />
                    <Popover
                      placement="bottom"
                      title="Info"
                      content={content}
                      trigger="click"
                      className="px-3"
                    >
                      <p>
                        <u>
                          <i>
                            *Klikk for å vite - Hvordan bruker jeg TFL-gavekort?
                          </i>
                        </u>
                      </p>
                    </Popover>

                    {couponData.map((data, index) => {
                      return (
                        <Col xs={{ span: 24 }} lg={{ span: 8 }} className="p-3">
                          <Card
                            className="giftCardBox"
                            // id="cardHistory"
                            key={data._id}
                          >
                            {/* <h3>TFL GAVEKORT</h3> */}
                            <img src={tflLogo} width={"250px"} />
                            {/* <img src={tflLogo} /> */}
                            <h5
                              className="d-flex flex-column"
                              style={{
                                // background: "#c5d794",
                                padding: "15px 0"
                              }}
                              // onClick={copyToClipboard}
                            >
                              <b>
                                <u>
                                  <textarea
                                    // ref={textAreaRef}
                                    value={`${data?.coupanCode}`}
                                    // readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px"
                                    }}
                                  />
                                  {data?.coupanCode} <FaCopy />
                                </u>
                              </b>
                              <span>
                                {" "}
                                {/* {copySuccess && <span>{copySuccess}</span>} */}
                              </span>
                            </h5>
                            <div>
                              <p>
                                Kupongverdi:{" "}
                                <span>Kr. {data?.coupanValue}</span>
                              </p>
                              <p>
                                Gjenværende verdi:{" "}
                                <span>Kr. {data?.reamainigValue}</span>
                              </p>
                              <p>
                                Utløpsdato :{" "}
                                <span>
                                  {data?.expirayDate ? (
                                    new Date(data.expirayDate).toLocaleString()
                                  ) : (
                                    <>{data?.expirayDate}</>
                                  )}
                                </span>
                              </p>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </TabPane>
              <TabPane
                tab="TFL Ordrehistorikk"
                key="tflOrderHistory"
                className="px-3 pb-4"
              >
                {loading ? (
                  <>
                    <Row>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Skeleton avatar paragraph={{ rows: 4 }} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {!tflHistoryCount ? (
                      <p className="text-dark">Du har ikke bestilt ennå!</p>
                    ) : (
                      <Row gutter={24} className="justify-content-between">
                        {tflResponseData.map((data, index) => {
                          return (
                            <Col
                              xs={24}
                              sm={24}
                              md={8}
                              lg={8}
                              xl={8}
                              className="px-3"
                            >
                              <Card className="historyCard mt-4" key={data._id}>
                                <Row
                                  gutter={24}
                                  className=""
                                  id="tflOrderHistory"
                                >
                                  <p className="w-100 text-center">
                                    <b>OTP-</b> <span>{data?.otp}</span>
                                  </p>
                                  <Divider />
                                  <div className="text-left d-flex justify-content-between w-100">
                                    <p>
                                      Order Date:{" "}
                                      <span>
                                        {data?.createdAt ? (
                                          new Date(
                                            data.createdAt
                                          ).toLocaleDateString()
                                        ) : (
                                          <>{data?.createdAt}</>
                                        )}
                                      </span>
                                    </p>
                                    <p>
                                      Order Time:{" "}
                                      <span>
                                        {data?.createdAt ? (
                                          new Date(
                                            data.createdAt
                                          ).toLocaleTimeString()
                                        ) : (
                                          <>{data?.createdAt}</>
                                        )}
                                      </span>
                                    </p>
                                  </div>

                                  <Divider />
                                  <div className="text-left">
                                    <h4>
                                      <b>Customer Details</b>
                                    </h4>
                                    <p>
                                      Name:{" "}
                                      <span>{data?.customerDetail?.name}</span>
                                    </p>
                                    <p>
                                      Phone:{" "}
                                      <span>
                                        {data?.customerDetail?.phoneNo}
                                      </span>
                                    </p>
                                    <p>
                                      E-Mail:{" "}
                                      <span>{data?.customerDetail?.email}</span>
                                    </p>
                                    <p>
                                      Address:{" "}
                                      <span>
                                        {data?.customerDetail?.address}
                                      </span>
                                    </p>
                                  </div>
                                  <Divider />
                                  <h4 className="text-right w-100">
                                    <b>Product Details</b>
                                  </h4>
                                  <Table
                                    className="w-100"
                                    dataSource={data?.items?.map((item) => ({
                                      key: item.key,
                                      name: item.name,
                                      quantity: item.quantity,
                                      totalAmount: item.totalAmount
                                    }))}
                                    columns={tflColumns}
                                  />
                                  <div className="text-left d-flex justify-content-between w-100">
                                    <p>Coin Used:</p>
                                    <p>{data?.coinsUsed}</p>
                                  </div>
                                  <div className="text-left d-flex justify-content-between w-100">
                                    <p>Payed Amount:</p>
                                    <p>{data?.totalAmount}</p>
                                  </div>
                                  <Divider />
                                  <>{getOrderSteps(data.orderStatus)}</>
                                </Row>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </>
                )}
              </TabPane>
              <TabPane
                tab="TFL Coins"
                key="points"
                className="px-0  pb-5"
                id="coins"
              >
                {loading ? (
                  <Row>
                    <Skeleton avatar paragraph={{ rows: 4 }} />
                  </Row>
                ) : (
                  <Row className="justify-content-center">
                    {" "}
                    <img src={coinsText} alt="coins text" className="px-3" />
                    <Col span={24}>
                      {" "}
                      <Collapse>
                        <Panel
                          header="Vi introduserer vårt banebrytende..."
                          key="1"
                        >
                          <p>
                            Vi introduserer vårt banebrytende
                            <b>
                              <i>TFL-myntsystem,</i>
                            </b>
                            som du kan tjene ved hver bestilling eller ved å
                            henvise
                            <b>
                              {" "}
                              <i>TD Ordering-appen </i>
                            </b>{" "}
                            til dine kjære. Disse myntene kan konverteres til
                            reelle verdier. Akkumuler{" "}
                            <b>
                              {" "}
                              <i>TFL-mynter </i>{" "}
                            </b>
                            enkelt med hver bestilling, og løs dem inn på Tree
                            Drive-plattformen for eksklusive belønninger.
                          </p>
                          <br />
                          <p>
                            {" "}
                            Bli med oss i dag for å låse opp en verden av
                            fordeler og besparelser, alt til fingerspissene. For
                            mer info besøk &nbsp;{" "}
                            <a href="https://treedrive.no">
                              <b>
                                <u>
                                  <i>www.treedrive.no</i>{" "}
                                </u>
                              </b>
                            </a>
                          </p>
                          <br />
                        </Panel>
                      </Collapse>
                    </Col>
                    <Col span={8} className="d-flex align-items-end mt-1">
                      <img src={coins} alt="coins" width="100%" />
                    </Col>
                    <Col
                      span={16}
                      className="px-3 pointsDisplay d-flex flex-column justify-content-center align-items-start"
                    >
                      <h5>Tilgjengelige mynter: {userPoints}</h5>
                      <h6>Brukte mynter: {pointsRedeemed}</h6>
                      <h6>
                        Totalt opptjente mynter:{" "}
                        <b>{userPoints + pointsRedeemed}</b>
                      </h6>
                    </Col>
                    <Col span={24}>
                      {" "}
                      <i>
                        <b>
                          <p className="px-3">
                            *Dine mynter vil bli oppdatert innen én time etter
                            ordrebekreftelsen.
                          </p>
                        </b>
                      </i>
                    </Col>
                    <Divider />
                    <Col span={24} className="d-flex justify-content-center">
                      <h5>
                        <br />
                        <b>Innløsningsordning</b>
                      </h5>
                      <br />
                    </Col>
                    <Col span={24}>
                      <Table
                        className="pointsTable"
                        columns={columnsData}
                        dataSource={userPointData}
                        bordered
                      />
                    </Col>
                    <Col span={24} className="px-3 pt-2">
                      <p>
                        <i>
                          <u>
                            *Sjekk gavekortene dine i gavekortfanen ovenfor!
                          </u>
                        </i>
                      </p>
                    </Col>
                    <Row className="p-3 bottomThought">
                      <Col span={24}>
                        <img src={bellBig} />
                      </Col>
                      <Col span={24} className="mt-3">
                        <p>The more you collect. The more you earn!</p>
                      </Col>
                    </Row>
                  </Row>
                )}
              </TabPane>
              <TabPane
                tab="Henvisningskode"
                key="referralCode"
                className="px-3 py-4"
              >
                <h2 className="text-dark">
                  {" "}
                  Henvisningskode :&nbsp;
                  <a
                    className="copytextCursor text-dark"
                    title="Copy Link"
                    onClick={() =>
                      navigator.clipboard.writeText(`${user.refferalCode}`)
                    }
                  >
                    {" "}
                    <u>
                      {user.refferalCode} <FaCopy />
                    </u>
                  </a>
                </h2>
              </TabPane>
            </Tabs>
          </Row>
        </section>
      )}
      <Dialog open={openModal} className="onloadModal">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Copon Code
        </DialogTitle>
        <DialogContent
          dividers
          className="d-flex flex-column align-items-center"
        >
          <a
            title="Copy Link"
            className="copytextCursor text-dark"
            onClick={() => navigator.clipboard.writeText(`${coinCode}`)}
          >
            <u>
              <h3>
                {coinCode} <FaCopy />
              </h3>
            </u>
          </a>
        </DialogContent>
        <DialogActions>
          <Button
            className="popModalBtn"
            autoFocus
            onClick={() => {
              window.location.reload();
            }}
          >
            Greit
          </Button>
        </DialogActions>
      </Dialog>
      {!isMobile && <BottomFooter />}
      {isMobile && <MobileNavBottom />}
      <ScrollToTop />
    </>
  );
}
